"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Account_1 = require("@web/components/Filters/Account");
var Address_1 = require("@web/components/Filters/Address");
var DateBirth_1 = require("@web/components/Filters/DateBirth");
var Discount_1 = require("@web/components/Filters/Discount");
var Period_1 = require("@web/components/Filters/Period");
var PurposeVisit_1 = require("@web/components/Filters/PurposeVisit");
var Sex_1 = require("@web/components/Filters/Sex");
var FiltersUsers = function () {
    return ((0, jsx_runtime_1.jsxs)("form", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Sex_1.SexFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(DateBirth_1.DateBirthFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Address_1.AddressFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Account_1.AccountFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(PurposeVisit_1.PurposeVisitFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Discount_1.DiscountFilter, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Period_1.PeriodFilter, {}) })] }));
};
exports.default = FiltersUsers;
