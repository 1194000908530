"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESOURCES = exports.EVENTS = void 0;
exports.EVENTS = [
    {
        event_id: 1,
        title: 'Видалення зуба',
        start: new Date(new Date(new Date().setHours(9)).setMinutes(30)),
        end: new Date(new Date(new Date().setHours(10)).setMinutes(30)),
        admin_id: 1
    },
    {
        event_id: 2,
        title: 'Обсеження',
        start: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
        end: new Date(new Date(new Date().setHours(11)).setMinutes(0)),
        admin_id: 2
    },
    {
        event_id: 3,
        title: 'Обсеження',
        start: new Date(new Date(new Date().setHours(10)).setMinutes(0)),
        end: new Date(new Date(new Date().setHours(11)).setMinutes(0)),
        admin_id: 3
    }
];
exports.RESOURCES = [
    {
        admin_id: 1,
        title: 'Ігор Іванович'
    },
    {
        admin_id: 2,
        title: 'Андрій Михайлович'
    },
    {
        admin_id: 3,
        title: 'Василь Ігорович'
    },
    {
        admin_id: 4,
        title: 'Mera'
    }
];
