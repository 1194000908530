"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NONE_PARAMETER = void 0;
exports.useNavigateTo = useNavigateTo;
exports.generateUrlParams = generateUrlParams;
var useFilters_1 = require("@web/utility/hooks/useFilters");
var react_router_dom_1 = require("react-router-dom");
function useNavigateTo() {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var filters = (0, useFilters_1.useFilters)();
    return function navigateTo(_a) {
        var pathname = _a.pathname, _b = _a.newFilters, newFilters = _b === void 0 ? {} : _b, _c = _a.resetScroll, resetScroll = _c === void 0 ? true : _c;
        var query = (0, useFilters_1.filtersToQuery)(__assign(__assign({}, filters), newFilters));
        navigate("".concat(pathname, "?").concat(query), { replace: false });
        if (resetScroll) {
            window.scrollTo(0, 0);
        }
    };
}
exports.NONE_PARAMETER = 0;
function generateUrlParams(_a) {
    var url = _a.url, activeTab = _a.activeTab, state = _a.state, _b = _a.id, id = _b === void 0 ? exports.NONE_PARAMETER : _b, subId = _a.subId;
    if (!activeTab)
        return "".concat(url, "/").concat(id);
    if (!state)
        return "".concat(url, "/").concat(id, "/").concat(activeTab);
    if (!subId) {
        return "".concat(url, "/").concat(id, "/").concat(activeTab, "/").concat(state);
    }
    return "".concat(url, "/").concat(id, "/").concat(activeTab, "/").concat(state, "/").concat(subId);
}
