"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var config_1 = require("@web/config");
var utility_1 = require("@web/utility");
var react_1 = require("react");
var UploadImageGallery = function (_a) {
    var selected = _a.selected, onChange = _a.onChange, clearImage = _a.clearImage;
    var fileInputRef = (0, react_1.useRef)(null);
    var uploadFile = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, response, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formData = new FormData();
                    formData.append('file', file);
                    return [4 /*yield*/, fetch("".concat(config_1.API_URL, "/upload"), {
                            method: 'POST',
                            body: formData
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    result = _a.sent();
                    onChange(result);
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("input", { type: "file", ref: fileInputRef, style: { display: 'none' }, onChange: function (e) {
                    if (e.target.files) {
                        uploadFile(e.target.files[0]);
                    }
                } }), (0, jsx_runtime_1.jsx)("div", { className: "add-files", children: (0, jsx_runtime_1.jsxs)("div", { className: "add-files__items", children: [selected.map(function (file, key) { return ((0, jsx_runtime_1.jsxs)("div", { className: "add-files__item", style: {
                                position: 'relative'
                            }, children: [(0, jsx_runtime_1.jsx)("div", { className: "add-files__preview", style: {
                                        backgroundImage: "url(".concat(config_1.API_URL, "/image/").concat(file.name, ")"),
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        height: '118px'
                                    } }), (0, jsx_runtime_1.jsx)("p", { className: "add-files__name", children: file.name }), (0, jsx_runtime_1.jsx)("span", { className: "add-files__size", children: (0, utility_1.formatBytes)(file.size) }), (0, jsx_runtime_1.jsx)("button", { className: "file__delete", type: "button", onClick: function () {
                                        clearImage(file);
                                    }, children: (0, jsx_runtime_1.jsxs)("svg", { width: "29", height: "29", viewBox: "0 0 29 29", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { width: "20", height: "20", transform: "translate(0 14.0665) rotate(-44.6944)", fill: "white", "fill-opacity": "0.01" }), (0, jsx_runtime_1.jsx)("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M17.0548 9.91477L14.1496 12.7891L11.2752 9.88394C10.9042 9.50892 10.2975 9.50568 9.92244 9.87673C9.54742 10.2478 9.54418 10.8545 9.91522 11.2295L12.7896 14.1347L9.88439 17.0091C9.50937 17.3801 9.50613 17.9869 9.87717 18.3619C10.2482 18.7369 10.855 18.7401 11.23 18.3691L14.1352 15.4947L17.0095 18.3999C17.3806 18.775 17.9873 18.7782 18.3623 18.4072C18.7374 18.0361 18.7406 17.4294 18.3696 17.0543L15.4952 14.1492L18.4004 11.2748C18.7754 10.9038 18.7786 10.297 18.4076 9.92199C18.0366 9.54697 17.4298 9.54373 17.0548 9.91477Z", fill: "#EE3248" })] }) })] }, key)); }), (0, jsx_runtime_1.jsxs)("button", { onClick: function () { return fileInputRef.current.click(); }, className: "add-files__field", style: {
                                height: '118px'
                            }, type: "button", children: [(0, jsx_runtime_1.jsx)("span", { children: "+" }), (0, jsx_runtime_1.jsx)("p", { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u0438 \u0444\u0430\u0439\u043B" })] })] }) })] }));
};
exports.default = UploadImageGallery;
