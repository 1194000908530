"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NameFilter = NameFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function NameFilter() {
    var _a;
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    var filter = (0, useFilters_1.useFilters)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: {
                name: e.target.value
            }
        });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "label", children: ["\u041D\u0430\u0437\u0432\u0430 \u043F\u043E\u0441\u043B\u0443\u0433\u0438", (0, jsx_runtime_1.jsx)("input", { type: "text", className: "input", value: (_a = filter.name) !== null && _a !== void 0 ? _a : '', onChange: onChange })] }));
}
