"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingIn = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var User_1 = require("@web/contexts/User");
var trpc_1 = require("@web/lib/trpc");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var SingIn = function () {
    var _a;
    var _b = (0, react_hook_form_1.useForm)({
        defaultValues: {
            login: '',
            password: ''
        }
    }), register = _b.register, handleSubmit = _b.handleSubmit, setError = _b.setError, errors = _b.formState.errors;
    var login = (0, react_1.useContext)(User_1.UserContext).login;
    var singInMutation = trpc_1.trpc.auth.login.useMutation();
    var onSubmitFun = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, singInMutation.mutateAsync(data)];
                case 1:
                    response = _b.sent();
                    login(response.token);
                    return [3 /*break*/, 3];
                case 2:
                    _a = _b.sent();
                    setError('login', {
                        type: 'manual',
                        message: 'Невірний логін або пароль'
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsx)("div", { className: "page__container", children: (0, jsx_runtime_1.jsx)("div", { className: "auth", children: (0, jsx_runtime_1.jsxs)("form", { className: "auth__form", onSubmit: handleSubmit(onSubmitFun), children: [(0, jsx_runtime_1.jsx)("h1", { className: "auth__title", children: "\u0412\u0445\u0456\u0434" }), (0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u041B\u043E\u0433\u0456\u043D", (0, jsx_runtime_1.jsx)("input", __assign({ type: "text", className: "input" }, register('login', { required: "Поле обов'язкове" }))), ((_a = errors.login) === null || _a === void 0 ? void 0 : _a.message) && ((0, jsx_runtime_1.jsx)("span", { className: "error", children: errors.login.message }))] }), (0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u041F\u0430\u0440\u043E\u043B\u044C", (0, jsx_runtime_1.jsx)("input", __assign({ type: "password", className: "input" }, register('password')))] }), (0, jsx_runtime_1.jsx)("button", { type: "submit", className: "btn btn-primary", children: "\u0423\u0432\u0456\u0439\u0442\u0438" })] }) }) }));
};
exports.SingIn = SingIn;
