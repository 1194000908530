"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SexFilter = SexFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var shared_1 = require("@space-start/shared");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function SexFilter() {
    var filters = (0, useFilters_1.useFilters)();
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: { sex: e.target.value }
        });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "label", children: ["\u0421\u0442\u0430\u0442\u044C", (0, jsx_runtime_1.jsxs)("div", { className: "options", children: [(0, jsx_runtime_1.jsxs)("div", { className: "options__item", children: [(0, jsx_runtime_1.jsx)("input", { onChange: onChange, id: "o_1", className: "options__input", checked: filters.sex === shared_1.SexEnum.MEN, type: "radio", value: shared_1.SexEnum.MEN, name: "option" }), (0, jsx_runtime_1.jsx)("label", { htmlFor: "o_1", className: "options__label", children: (0, jsx_runtime_1.jsx)("span", { className: "options__text", children: "\u0427\u043E\u043B\u043E\u0432\u0456\u043A" }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "options__item", children: [(0, jsx_runtime_1.jsx)("input", { onChange: onChange, id: "o_2", className: "options__input", type: "radio", checked: filters.sex === shared_1.SexEnum.WOMEN, value: shared_1.SexEnum.WOMEN, name: "option" }), (0, jsx_runtime_1.jsx)("label", { htmlFor: "o_2", className: "options__label", children: (0, jsx_runtime_1.jsx)("span", { className: "options__text", children: "\u0416\u0456\u043D\u043A\u0430" }) })] })] })] }));
}
