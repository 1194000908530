"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilters = useFilters;
var react_router_dom_1 = require("react-router-dom");
function useFilters(defaultFilters) {
    var _a = (0, react_router_dom_1.useSearchParams)(), searchParams = _a[0], setSearchParams = _a[1];
    var filters = Object.fromEntries(Object.entries(defaultFilters).map(function (_a) {
        var _b;
        var key = _a[0], defaultValue = _a[1];
        return [
            key,
            (_b = searchParams.get(key)) !== null && _b !== void 0 ? _b : defaultValue
        ];
    }));
    var setFilters = function (newFilters) {
        var updatedParams = new URLSearchParams(searchParams);
        Object.entries(newFilters).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (value) {
                updatedParams.set(key, value);
            }
            else {
                updatedParams.delete(key);
            }
        });
        setSearchParams(updatedParams, { replace: true });
    };
    return { filters: filters, setFilters: setFilters };
}
