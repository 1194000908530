"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Account_1 = require("@web/components/Filters/Account");
var City_1 = require("@web/components/Filters/City");
var NameClinic_1 = require("@web/components/Filters/NameClinic");
var FiltersAddress = function () {
    return ((0, jsx_runtime_1.jsxs)("form", { className: "filter__form", children: [(0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(City_1.City, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(NameClinic_1.NameClinic, {}) }), (0, jsx_runtime_1.jsx)("div", { className: "filter__widget", children: (0, jsx_runtime_1.jsx)(Account_1.AccountFilter, {}) })] }));
};
exports.default = FiltersAddress;
