"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var enum_1 = require("@web/enum");
var routers_1 = require("@web/routers");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var MenuList = function () {
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    return ((0, jsx_runtime_1.jsx)("nav", { className: "menu", children: (0, jsx_runtime_1.jsxs)("ul", { className: "menu__list", children: [(0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("button", { className: "menu__link", onClick: function () { return navigateTo({ pathname: routers_1.homeUrl }); }, children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/1.svg", alt: "1", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("button", { className: "menu__link", onClick: function () {
                            return navigateTo({
                                pathname: (0, useNavigateTo_1.generateUrlParams)({
                                    id: useNavigateTo_1.NONE_PARAMETER,
                                    activeTab: enum_1.UsersActiveTabs.PROFILE,
                                    state: enum_1.StateTabs.VIEW,
                                    url: routers_1.usersUrl
                                })
                            });
                        }, children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/2.svg", alt: "2", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("button", { onClick: function () {
                            return navigateTo({
                                pathname: (0, useNavigateTo_1.generateUrlParams)({
                                    id: useNavigateTo_1.NONE_PARAMETER,
                                    activeTab: enum_1.DoctorActiveTabs.PROFILE,
                                    state: enum_1.StateTabs.VIEW,
                                    url: routers_1.doctorsUrl
                                })
                            });
                        }, className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/3.svg", alt: "3", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("button", { onClick: function () {
                            return navigateTo({
                                pathname: (0, useNavigateTo_1.generateUrlParams)({
                                    id: useNavigateTo_1.NONE_PARAMETER,
                                    activeTab: enum_1.DefaultActiveTabs.INFO,
                                    state: enum_1.StateTabs.VIEW,
                                    url: routers_1.servicesUrl
                                })
                            });
                        }, className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/4.svg", alt: "4", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/5.svg", alt: "5", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("button", { onClick: function () {
                            return navigateTo({
                                pathname: (0, useNavigateTo_1.generateUrlParams)({
                                    url: routers_1.addressUrl,
                                    activeTab: enum_1.AddressActiveTabs.PROFILE,
                                    state: enum_1.StateTabs.VIEW
                                })
                            });
                        }, className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/6.svg", alt: "6", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/7.svg", alt: "7", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/8.svg", alt: "8", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/9.svg", alt: "9", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/10.svg", alt: "10", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/11.svg", alt: "11", style: { maxWidth: '100%' } }) }) }), (0, jsx_runtime_1.jsx)("li", { className: "menu__item", children: (0, jsx_runtime_1.jsx)("a", { href: "#", className: "menu__link", children: (0, jsx_runtime_1.jsx)("img", { src: "/assets/svg/12.svg", alt: "12", style: { maxWidth: '100%' } }) }) })] }) }));
};
exports.default = MenuList;
