"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveFilter = ActiveFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function ActiveFilter() {
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: { isActiveStatus: e.target.value === '1' }
        });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "label", children: ["\u0421\u0442\u0430\u0442\u0443\u0441 \u043F\u043E\u0441\u043B\u0443\u0433\u0438", (0, jsx_runtime_1.jsxs)("div", { className: "options", children: [(0, jsx_runtime_1.jsxs)("div", { className: "options__item", children: [(0, jsx_runtime_1.jsx)("input", { onChange: onChange, id: "o_1", className: "options__input", value: "1", type: "radio", name: "option" }), (0, jsx_runtime_1.jsx)("label", { htmlFor: "o_1", className: "options__label", children: (0, jsx_runtime_1.jsx)("span", { className: "options__text", children: "\u0410\u043A\u0442\u0438\u0432\u043D\u0430" }) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "options__item", children: [(0, jsx_runtime_1.jsx)("input", { onChange: onChange, id: "o_2", className: "options__input", value: "0", type: "radio", name: "option" }), (0, jsx_runtime_1.jsx)("label", { htmlFor: "o_2", className: "options__label", children: (0, jsx_runtime_1.jsx)("span", { className: "options__text", children: "\u041D\u0435 \u0430\u043A\u0442\u0438\u0432\u043D\u0430" }) })] })] })] }));
}
