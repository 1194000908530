"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressFilter = AddressFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function AddressFilter() {
    var filters = (0, useFilters_1.useFilters)();
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: { address: e.target.value }
        });
    }
    return ((0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u0410\u0434\u0440\u0435\u0441\u0430 \u043F\u0440\u043E\u0436\u0438\u0432\u0430\u043D\u043D\u044F", (0, jsx_runtime_1.jsx)("input", { type: "text", onChange: onChange, className: "input", value: filters.address, placeholder: "\u041C\u0456\u0441\u0442\u043E" })] }));
}
