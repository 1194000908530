"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatBytes = exports.classNames = void 0;
var classNames = function () {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(' ');
};
exports.classNames = classNames;
var formatBytes = function (bytes) {
    if (bytes >= 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
    else {
        return (bytes / 1024).toFixed(2) + ' KB';
    }
};
exports.formatBytes = formatBytes;
