"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceFilter = PriceFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function PriceFilter() {
    var _a, _b;
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    var filters = (0, useFilters_1.useFilters)();
    function onChange(e) {
        var _a;
        navigateTo({
            pathname: location.pathname,
            newFilters: (_a = {},
                _a[e.target.name] = e.target.value,
                _a)
        });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { className: "label", children: ["\u0426\u0456\u043D\u0430", (0, jsx_runtime_1.jsxs)("div", { className: "filter__line", children: [(0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u0412\u0456\u0434", (0, jsx_runtime_1.jsx)("input", { type: "number", name: "priceFrom", value: (_a = filters.priceFrom) !== null && _a !== void 0 ? _a : '', onChange: onChange, className: "input" })] }), (0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u0414\u043E", (0, jsx_runtime_1.jsx)("input", { type: "number", name: "priceTo", value: (_b = filters.priceTo) !== null && _b !== void 0 ? _b : '', onChange: onChange, className: "input" })] })] })] }));
}
