"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var utility_1 = require("@web/utility");
var getInitials = function (name) {
    var initials = name.split(' ');
    return initials.length > 1
        ? "".concat(initials[0][0]).concat(initials[1][0]).toUpperCase()
        : initials[0][0].toUpperCase();
};
var getColorByLetter = function (letter) {
    // Визначимо кольори для різних букв
    var colors = {
        A: '#FFC107', // Приклад кольорів
        B: '#FF5722',
        C: '#03A9F4',
        D: '#8BC34A',
        E: '#009688',
        F: '#673AB7',
        G: '#607D8B',
        H: '#FF9800',
        I: '#9C27B0',
        J: '#4CAF50',
        K: '#2196F3',
        L: '#E91E63',
        M: '#795548',
        N: '#FFEB3B',
        O: '#00BCD4',
        P: '#CDDC39',
        Q: '#FF5722',
        R: '#FFC107',
        S: '#03A9F4',
        T: '#8BC34A',
        U: '#009688',
        V: '#673AB7',
        W: '#607D8B',
        X: '#FF9800',
        Y: '#9C27B0',
        Z: '#4CAF50',
        А: '#FFC107',
        Б: '#FF5722',
        В: '#03A9F4',
        Г: '#8BC34A',
        Д: '#009688',
        Е: '#673AB7',
        Ж: '#607D8B',
        З: '#FF9800',
        И: '#9C27B0',
        Й: '#4CAF50',
        К: '#2196F3',
        Л: '#E91E63',
        М: '#795548',
        Н: '#FFEB3B',
        О: '#00BCD4',
        П: '#CDDC39',
        Р: '#FF5722',
        С: '#FFC107',
        Т: '#03A9F4',
        У: '#8BC34A',
        Ф: '#009688',
        Х: '#673AB7',
        Ц: '#607D8B',
        Ч: '#FF9800',
        Ш: '#9C27B0',
        Щ: '#4CAF50',
        Ь: '#2196F3',
        Ю: '#E91E63',
        Я: '#795548'
    };
    return colors[letter.toUpperCase()] || '#6C757D'; // Default color
};
var Avatar = function (_a) {
    var src = _a.src, _b = _a.alt, alt = _b === void 0 ? '' : _b, _c = _a.height, height = _c === void 0 ? 32 : _c, _d = _a.width, width = _d === void 0 ? 32 : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.text, text = _f === void 0 ? '' : _f;
    var initials = getInitials(text);
    var backgroundColor = getColorByLetter(initials[0] || 'A'); // Основний колір фону
    var textColor = '#ffffff'; // Білий текст для контрасту
    return src ? ((0, jsx_runtime_1.jsx)("div", { className: "relative inline-block", style: {
            width: "".concat(width, "px"),
            height: "".concat(height, "px")
        }, children: (0, jsx_runtime_1.jsx)("img", { className: (0, utility_1.classNames)('rounded-circle border border-white', className), src: src, alt: alt, style: {
                minWidth: "".concat(width, "px"),
                minHeight: "".concat(height, "px")
            }, height: "".concat(height, "px"), width: "".concat(width, "px") }) })) : ((0, jsx_runtime_1.jsx)("div", { className: (0, utility_1.classNames)('d-flex align-items-center justify-content-center rounded-circle', className), style: {
            width: "".concat(width, "px"),
            height: "".concat(height, "px"),
            backgroundColor: backgroundColor,
            color: textColor,
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: '100%',
            paddingTop: '5px'
        }, children: initials }));
};
exports.default = Avatar;
