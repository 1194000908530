"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchFilter = SearchFilter;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function SearchFilter() {
    var _a;
    var filters = (0, useFilters_1.useFilters)();
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: { search: e.target.value }
        });
    }
    return ((0, jsx_runtime_1.jsx)("input", { type: "text", className: "search__input", placeholder: "\u041F\u043E\u0448\u0443\u043A", onChange: onChange, value: (_a = filters.search) !== null && _a !== void 0 ? _a : '' }));
}
