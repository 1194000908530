"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountEnum = exports.CategoriesEnum = exports.CabinetEnum = exports.ClinicAddressEnum = exports.CurrencyEnum = exports.TypeOfficeEnum = exports.DayEnum = exports.SpecializationEnum = exports.PurposeVisitEnum = exports.InteractionUserEnum = exports.AccountRoleEnum = exports.EventTypeEnum = exports.SexEnum = exports.DefaultResponseCodeEnum = exports.GmailAnalyticsEnum = void 0;
var GmailAnalyticsEnum;
(function (GmailAnalyticsEnum) {
    GmailAnalyticsEnum["EMAILS_SENT"] = "EMAILS_SENT";
    GmailAnalyticsEnum["EMAILS_RECEIVED"] = "EMAILS_RECEIVED";
})(GmailAnalyticsEnum || (exports.GmailAnalyticsEnum = GmailAnalyticsEnum = {}));
var DefaultResponseCodeEnum;
(function (DefaultResponseCodeEnum) {
    DefaultResponseCodeEnum["SUCCESS"] = "SUCCESS";
    DefaultResponseCodeEnum["ERROR"] = "ERROR";
})(DefaultResponseCodeEnum || (exports.DefaultResponseCodeEnum = DefaultResponseCodeEnum = {}));
var SexEnum;
(function (SexEnum) {
    SexEnum["MEN"] = "MEN";
    SexEnum["WOMEN"] = "WOMEN";
})(SexEnum || (exports.SexEnum = SexEnum = {}));
var EventTypeEnum;
(function (EventTypeEnum) {
    EventTypeEnum["VISIT"] = "VISIT";
    EventTypeEnum["CUSTOM"] = "CUSTOM";
    EventTypeEnum["EVENT"] = "EVENT";
})(EventTypeEnum || (exports.EventTypeEnum = EventTypeEnum = {}));
var AccountRoleEnum;
(function (AccountRoleEnum) {
    AccountRoleEnum["ADMINISTRATOR"] = "ADMINISTRATOR";
    AccountRoleEnum["DOCTOR"] = "DOCTOR";
    AccountRoleEnum["MANAGER"] = "MANAGER";
})(AccountRoleEnum || (exports.AccountRoleEnum = AccountRoleEnum = {}));
var InteractionUserEnum;
(function (InteractionUserEnum) {
    InteractionUserEnum["PHONE"] = "PHONE";
    InteractionUserEnum["VIBER"] = "VIBER";
    InteractionUserEnum["SMS"] = "SMS";
    InteractionUserEnum["EMAIL"] = "EMAIL";
    InteractionUserEnum["VISIT"] = "VISIT";
    InteractionUserEnum["SERVISE"] = "SERVISE";
})(InteractionUserEnum || (exports.InteractionUserEnum = InteractionUserEnum = {}));
var PurposeVisitEnum;
(function (PurposeVisitEnum) {
    PurposeVisitEnum["CONSULTATION"] = "CONSULTATION";
    PurposeVisitEnum["HELLP"] = "HELLP";
    PurposeVisitEnum["TREATMENT"] = "TREATMENT";
    PurposeVisitEnum["OTHER"] = "OTHER";
    PurposeVisitEnum["VISIT"] = "VISIT";
})(PurposeVisitEnum || (exports.PurposeVisitEnum = PurposeVisitEnum = {}));
var SpecializationEnum;
(function (SpecializationEnum) {
    SpecializationEnum["Therapist"] = "\u0422\u0435\u0440\u0430\u043F\u0435\u0432\u0442";
    SpecializationEnum["Orthodontist"] = "\u041E\u0440\u0442\u043E\u0434\u043E\u043D\u0442";
    SpecializationEnum["Surgeon"] = "\u0425\u0456\u0440\u0443\u0440\u0433";
    SpecializationEnum["Periodontist"] = "\u041F\u0430\u0440\u043E\u0434\u043E\u043D\u0442\u043E\u043B\u043E\u0433";
    SpecializationEnum["Endodontist"] = "\u0415\u043D\u0434\u043E\u0434\u043E\u043D\u0438\u0441\u0442";
    SpecializationEnum["Implantologist"] = "\u0406\u043C\u043F\u043B\u0430\u043D\u0442\u043E\u043B\u043E\u0433";
    SpecializationEnum["Prosthetist"] = "\u041F\u0440\u043E\u0442\u0435\u0437\u0438\u0441\u0442";
})(SpecializationEnum || (exports.SpecializationEnum = SpecializationEnum = {}));
var DayEnum;
(function (DayEnum) {
    DayEnum["MON"] = "mon";
    DayEnum["TUE"] = "tue";
    DayEnum["WED"] = "wed";
    DayEnum["THU"] = "thu";
    DayEnum["FRI"] = "fri";
    DayEnum["SAT"] = "sat";
    DayEnum["SUN"] = "sun";
})(DayEnum || (exports.DayEnum = DayEnum = {}));
var TypeOfficeEnum;
(function (TypeOfficeEnum) {
    TypeOfficeEnum["THERAPEUTIC"] = "therapeutic";
    TypeOfficeEnum["SURGICAL"] = "surgical";
    TypeOfficeEnum["ORTHODONTIC"] = "orthodontic";
    TypeOfficeEnum["PERIODONTIC"] = "periodontic";
    TypeOfficeEnum["ENDODONTIC"] = "endodontic";
    TypeOfficeEnum["IMPLANTOLOGIC"] = "implantologic";
    TypeOfficeEnum["PROSTHETIC"] = "prosthetic";
})(TypeOfficeEnum || (exports.TypeOfficeEnum = TypeOfficeEnum = {}));
var CurrencyEnum;
(function (CurrencyEnum) {
    CurrencyEnum["UAH"] = "UAH";
    CurrencyEnum["USD"] = "USD";
    CurrencyEnum["EUR"] = "EUR";
})(CurrencyEnum || (exports.CurrencyEnum = CurrencyEnum = {}));
var ClinicAddressEnum;
(function (ClinicAddressEnum) {
    ClinicAddressEnum["Address_1"] = "\u0416\u0438\u0432\u043E\u0432\u0430 37A";
    ClinicAddressEnum["Address_2"] = "\u0416\u0438\u0432\u043E\u0432\u0430 38A";
})(ClinicAddressEnum || (exports.ClinicAddressEnum = ClinicAddressEnum = {}));
var CabinetEnum;
(function (CabinetEnum) {
    CabinetEnum["Cabinet_27"] = "27";
    CabinetEnum["Cabinet_28"] = "28";
})(CabinetEnum || (exports.CabinetEnum = CabinetEnum = {}));
var CategoriesEnum;
(function (CategoriesEnum) {
    CategoriesEnum["TREATMENT"] = "TREATMENT";
    CategoriesEnum["DIAGNOSTICS"] = "DIAGNOSTICS";
    CategoriesEnum["PROSTHETICS"] = "PROSTHETICS";
    CategoriesEnum["ORTHODONTICS"] = "ORTHODONTICS";
    CategoriesEnum["AESTHETIC_DENTISTRY"] = "AESTHETIC_DENTISTRY";
    CategoriesEnum["SURGERY"] = "SURGERY";
    CategoriesEnum["PREVENTION"] = "PREVENTION";
})(CategoriesEnum || (exports.CategoriesEnum = CategoriesEnum = {}));
var DiscountEnum;
(function (DiscountEnum) {
    DiscountEnum["PENSIONER"] = "PENSIONER";
    DiscountEnum["VETERANS"] = "VETERANS";
    DiscountEnum["STUDENTS"] = "STUDENTS";
})(DiscountEnum || (exports.DiscountEnum = DiscountEnum = {}));
