"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NameClinic = NameClinic;
var jsx_runtime_1 = require("react/jsx-runtime");
var useFilters_1 = require("@web/utility/hooks/useFilters");
var useNavigateTo_1 = require("@web/utility/hooks/useNavigateTo");
var react_router_dom_1 = require("react-router-dom");
function NameClinic() {
    var filters = (0, useFilters_1.useFilters)();
    var navigateTo = (0, useNavigateTo_1.useNavigateTo)();
    var location = (0, react_router_dom_1.useLocation)();
    function onChange(e) {
        navigateTo({
            pathname: location.pathname,
            newFilters: { clinic: e.target.value }
        });
    }
    return ((0, jsx_runtime_1.jsxs)("label", { className: "label", children: ["\u0412\u0435\u0441\u0442\u0440\u0430", (0, jsx_runtime_1.jsx)("input", { type: "text", onChange: onChange, className: "input", value: filters.clinic, placeholder: "\u041F\u043E\u0448\u0443\u043A \u043A\u043B\u0456\u043D\u0456\u043A\u0438" })] }));
}
